import React from 'react';

import { Nav, NavItem } from 'reactstrap';
import { Link } from 'react-scroll';

import NavBar from 'aulatime/src/components/NavBar';

import Logo from '../img/logo_nav.png';

const NavBarMarketing = () => (
  <NavBar
    logo={<img src={Logo} alt="Aula English Time" className="img-fluid" />}
    nav={(menuClick, offset) => (
      <Nav navbar>
        <NavItem>
          <a href="/" className="nav-link">Inicio</a>
        </NavItem>
        <NavItem>
          <a href="/oferta-de-clases-online" className="nav-link flashing">Oferta de clases online</a>
        </NavItem>
        <NavItem className="tier-1">
          <a href="/invita-a-un-amigo" className="nav-link">Invita a un amigo</a>
        </NavItem>
        <NavItem>
          <Link href="#" className="nav-link" activeclassname="active" to="contacto" spy={true} smooth={"easeInOutCubic"} duration={1500} offset={offset} onClick={menuClick}>
            Contáctanos
          </Link>
        </NavItem>
      </Nav>
    )}
  />
);

export default NavBarMarketing;
