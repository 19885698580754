import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import NavBarMarketing from '../NavBarMarketing';
import DividerLeft from 'aulatime/src/components/DividerLeft';
import ArrowDown from 'aulatime/src/components/ArrowDown';

const primaryColor = '#003399';

const Header = () => {

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            station {
              metadata {
                shortcode
              }
            }
          }
        }
        banner01: file(relativePath: { eq: "oferta-de-clases-online/banner-01.jpg" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 2625) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );

  return (
    <header>
      <NavBarMarketing/>
      <ArrowDown fragment="saludar"/>

      <BackgroundImage
        Tag="div"
        className="bg bg-01"
        fluid={data.banner01.childImageSharp.fluid}
        backgroundColor={primaryColor}
      />
      <div className="hero-unit container">
        <div className="row align-items-center">
          <div className="col-9 col-sm-8">
            <h1>Oferta de</h1>
            <h1><span className="text-primary-2">clases online</span></h1>
          </div>
        </div>
      </div>

      <DividerLeft/>
    </header>
  );
}

export default Header;
