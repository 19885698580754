import React, { useState } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Header from '../components/Header';
import Footer from '../components/Footer';

import ChunkBienvenidos from '../components/ChunkBienvenidos';
import ChunkCursos from '../components/ChunkCursos';

import SectionProfesionales from '../components/SectionProfesionales';

import ChunkDigital from '../components/ChunkDigital';
import ChunkExamenes from '../components/ChunkExamenes';
import ChunkTestimonios from '../components/ChunkTestimonios';
import SectionContacto from 'aulatime/src/components/SectionContacto';

import PopupRebajas from 'aulatime/src/components/PopupRebajas';

const IndexPage = ({ data }) => {

  const googleMapsConfig = data.site.siteMetadata.googleMaps;

  const postmanpConfig = {
    metadata: {
      ...data.site.siteMetadata.station.metadata,
      ...{ lang: process.env.GATSBY_LOCALE || 'es' },
      ...{ form: 'contact', section: '#Contactanos' }
    }
  };

  const privacyState = useState(false);
  const popupBody = <>
    <div>
      <h4>Oferta</h4>
      <h5>40 horas</h5>
    </div>
    <div className="price text-center py-md-1 py-2">
      <h5>€389,<span>99</span></h5>
      <p className="mb-md-1 mb-2">IVA incl.</p>
    </div>
    <a href="/oferta-de-clases-online" className="btn btn-sm btn-primary">Saber más</a>
  </>

  return (
    <Layout privacyState={privacyState}>
      <SEO title="Academia de Inglés | Cursos de Inglés Online | Preparación Exámenes de Cambridge | Aptis | IELTS | Language School"/>
      <main id="index">
        <Header/>
        <ChunkBienvenidos/>
        <ChunkCursos/>
        <SectionProfesionales/>
        <ChunkDigital/>
        <ChunkExamenes/>
        <ChunkTestimonios/>
        <SectionContacto
          googleMapsConfig={googleMapsConfig}
          postmanpConfig={postmanpConfig}
          email={data.site.siteMetadata.station.email}
        />
        <Footer/>
      </main>
      <PopupRebajas body={popupBody} privacyState={privacyState} />
    </Layout>
  );
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        station {
          email
          metadata {
            domain
            shortcode
          }
        }
        googleMaps {
          apikey
          mapid
          coords {
            lat
            lng
          }
          markerColor
        }
      }
    }
  }
`
