import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { jt } from 'ttag';

import Layout from '../components/layout';
import SEO from '../components/seo';

import Header from '../components/oferta-de-clases-online/Header';
import Footer from '../components/Footer';

import ContactForm from 'contactfo';

import DiagonalLine from 'aulatime/src/components/DiagonalLine';

import FreshChat from 'aulatime/src/components/FreshChat';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faPhone);

const OfertaDeClasesOnlinePage = ({ data }) => {

  useEffect(
    () => {

      const date = new Date(new Date().setMinutes(new Date().getMinutes() + 10)).toUTCString();
      document.cookie = `popupRebajas=false; Expires=${date}; Path=/; Secure; SameSite=Lax`;

    }, []
  );

  const postmanpConfig = {
    ...data.site.siteMetadata.station.metadata,
    ...{ lang: process.env.GATSBY_LOCALE || 'es' },
    ...{ form: 'marketing', plan: '#OfertaDeClasesOnline' }
  };

  const email = data.site.siteMetadata.station.email;

  return (
    <Layout>
      <SEO title="Oferta de clases online"/>
      <main id="oferta-de-clases-online" className="marketing">
        <Header/>
        <section id="saludar">
          <div className="container">
            <div className="row">

              <div className="col-lg-10 offset-lg-1 col-12 text-center">

                <div className="great-vibes">
                  <h3>Oferta de</h3>
                  <h4>clases online</h4>
                </div>

                <h2 className="pt-4"><span className="text-primary-2">40 horas</span> de tutoría privada online</h2>
                <div className="price-comparison">
                  <p className="pt-4 lead mb-0">Antes</p>
                  <div className="row justify-content-center">
                    <div className="col-auto price">
                      <div>
                        <h5>€499,<span>99</span></h5>
                      </div>
                      <DiagonalLine/>
                    </div>
                  </div>

                  <p className="mb-0 pt-3 h3 font-weight-bold">Ahora</p>
                  <div className="row justify-content-center">
                    <div className="col-auto price">
                      <h5>€389,<span>99</span></h5>
                      <p className="small">IVA incl.</p>
                    </div>
                  </div>
                </div>

                <h4 className="text-primary-2 pt-3">¿Por qué elegirnos?</h4>
                <ul className="list-group list-group-flush lead text-center">
                  <li className="list-group-item">Clases privadas online y atención <span className="text-primary-2 font-weight-bold">individual</span> con un especialista dedicado a <span className="font-weight-bold">sacar el título en tiempo récord</span>.</li>
                  <li className="list-group-item">Horas 100% recuperables.</li>
                </ul>

                <p className="h3 pt-4 text-primary-2">¡Reserva tu plaza ya!</p>

              </div>

            </div>
          </div>
        </section>

        <section id="contacto">
          <div id="contactoForm">
            <div className="container">

              <div className="rounded col-lg-8 offset-lg-2 col-12 px-0">
                <ContactForm
                  metadata={postmanpConfig}
                />
              </div>
            </div>
          </div>

          <div id="contactoDatos">
            <div className="container">

              <div className="rounded col-lg-8 offset-lg-2 col-12 px-0">
                <div className="card h-100">
                  <FontAwesomeIcon icon="phone" />
                  <div className="card-body">

                    <h5>{jt`Contacto`}</h5>
                    <h3>+34 644 80 37 77</h3>
                    <p className="pt-3 mb-0"><strong>Email</strong>:<br /><a href={`mailto:${email}`}>{email}</a></p>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        <Footer/>
      </main>
      <FreshChat config={data.site.siteMetadata.freshchat}/>
    </Layout>
  );
}

export default OfertaDeClasesOnlinePage

export const query = graphql`
  query {
    site {
      siteMetadata {
        freshchat {
          token
          host
          locale
          siteId
          tags
          config {
            headerProperty {
              appName
              appLogo
              backgroundColor
            }
          }
        }
        station {
          email
          metadata {
            domain
            shortcode
          }
        }
      }
    }
  }
`
